import kotlinx.datetime.TimeZone
import model.Time
import kotlin.time.Duration
import kotlin.time.Duration.Companion.days
import kotlin.time.Duration.Companion.hours
import kotlin.time.Duration.Companion.minutes

data class LocalDateYearInvariant(val monthNumber: Int, val dayOfMonth: Int)

enum class LoginMethod(val buttonName: String) {
    VIBBO("Login with Vibbo"),
    ALT("Login without Vibbo")
}

object Settings {
    val timeZone = TimeZone.of("Europe/Oslo")
    val minTime = Time(11, 0, zone = timeZone)
    val maxTime = Time(23, 0, zone = timeZone)
    val minDuration = 30.minutes
    val maxDuration: Duration = 24.hours  // Effectively disabled
    val maxDurationAhead = (31 * 2).days
    const val maxBookingsAhead = 2
    const val minuteStep = 15
    const val adminCanDeleteAllBookings = false
    val loginMethods = listOf(LoginMethod.VIBBO, /*LoginMethod.ALT*/)  // First is highlighted
    val invalidDates = listOf(LocalDateYearInvariant(5, 17))
}