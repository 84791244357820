package api

import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.engine.js.*
import io.ktor.client.plugins.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.client.request.*
import io.ktor.http.*
import io.ktor.serialization.kotlinx.json.*
import kotlinx.browser.window
import kotlinx.serialization.json.Json
import model.*

private val json = Json { ignoreUnknownKeys = true }
private val client = HttpClient(Js) {
    expectSuccess = true
    install(ContentNegotiation) { json(json) }
    install(DefaultRequest) {
        url {
            host = window.location.hostname
            port = window.location.port.takeIf { it.isNotBlank() }?.toInt() ?: DEFAULT_PORT
            if (window.location.protocol == "https:") {
                protocol = when (protocol) {
                    URLProtocol.HTTP -> URLProtocol.HTTPS
                    URLProtocol.WS -> URLProtocol.WSS
                    else -> protocol
                }
            }
        }
    }
}

private fun HttpRequestBuilder.json(data: Any) {
    contentType(ContentType.Application.Json)
    setBody(data)
}

interface TokenApiBase {
    suspend fun loginVibboStart(data: LoginStartRequest): LoginStartResponse
    suspend fun loginVibboEnd(data: LoginEndRequest): TokenResponse
    suspend fun loginAlt(data: LoginAltRequest): TokenResponse
}

interface ApiBase {
    suspend fun createBooking(data: CreateBookingRequest): BookingResponse
    suspend fun getBookings(): List<BookingResponse>
    suspend fun deleteBooking(bookingId: String): BookingResponse
}

object TokenApi : TokenApiBase {
    override suspend fun loginVibboStart(data: LoginStartRequest) =
        client.post("api/token/start") { json(data) }.body<LoginStartResponse>()

    override suspend fun loginVibboEnd(data: LoginEndRequest) =
        client.post("api/token/end") { json(data) }.body<TokenResponse>()

    override suspend fun loginAlt(data: LoginAltRequest) =
        client.post("api/loginAlt") { json(data) }.body<TokenResponse>()

}

class Api(private val token: String) : ApiBase {
    private fun HttpRequestBuilder.token() {
        headers { append("authorization", "Bearer $token") }
    }

    override suspend fun createBooking(data: CreateBookingRequest) =
        client.post("api/bookings") {
            token()
            json(data)
        }.body<BookingResponse>()

    override suspend fun getBookings() =
        client.get("api/bookings") { token() }.body<List<BookingResponse>>()

    override suspend fun deleteBooking(bookingId: String) =
        client.delete("api/bookings/$bookingId") { token() }.body<BookingResponse>()

}